//Export2Excel.js
/* eslint-disable */
require('script-loader!file-saver');
require('./Blob.js');//转二进制用  这边要写你的blob的实际地址
require('script-loader!xlsx/dist/xlsx.core.min');
import XLSX from 'xlsx-style'
function generateArray(table) {
    const out = [];
    const rows = table.querySelectorAll('tr');
    const ranges = [];
    for (let R = 0; R < rows.length; ++R) {
        const outRow = [];
        const row = rows[R];
        const columns = row.querySelectorAll('td');
        for (let C = 0; C < columns.length; ++C) {
            const cell = columns[C];
            let colspan = cell.getAttribute('colspan');
            let rowspan = cell.getAttribute('rowspan');
            let cellValue = cell.innerText;
            if (cellValue !== "" && cellValue == +cellValue) cellValue = +cellValue;

            //Skip ranges
            ranges.forEach(function (range) {
                if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
                    for (let i = 0; i <= range.e.c - range.s.c; ++i) outRow.push(null);
                }
            });

            //Handle Row Span
            if (rowspan || colspan) {
                rowspan = rowspan || 1;
                colspan = colspan || 1;
                ranges.push({
                    s: {
                        r: R,
                        c: outRow.length
                    },
                    e: {
                        r: R + rowspan - 1,
                        c: outRow.length + colspan - 1
                    }
                });
            }

            //Handle Value
            outRow.push(cellValue !== "" ? cellValue : null);

            //Handle Colspan
            if (colspan)
                for (let k = 0; k < colspan - 1; ++k) outRow.push(null);
        }
        out.push(outRow);
    }
    return [out, ranges];
}

function datenum(v, date1904) {
    if (date1904) v += 1462;
    const epoch = Date.parse(v);
    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function sheet_from_array_of_arrays(data, opts) {
    const ws = {};
    const range = {
        s: {
            c: 10000000,
            r: 10000000
        },
        e: {
            c: 0,
            r: 0
        }
    };
    for (let R = 0; R != data.length; ++R) {
        for (let C = 0; C != data[R].length; ++C) {
            if (range.s.r > R) range.s.r = R;
            if (range.s.c > C) range.s.c = C;
            if (range.e.r < R) range.e.r = R;
            if (range.e.c < C) range.e.c = C;
            const cell = {
                v: data[R][C]
            };
            if (cell.v == null) continue;
            const cell_ref = XLSX.utils.encode_cell({
                c: C,
                r: R
            });

            if (typeof cell.v === 'number') cell.t = 'n';
            else if (typeof cell.v === 'boolean') cell.t = 'b';
            else if (cell.v instanceof Date) {
                cell.t = 'n';
                cell.z = XLSX.SSF._table[14];
                cell.v = datenum(cell.v);
            } else cell.t = 's';

            ws[cell_ref] = cell;
        }
    }
    if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
    return ws;
}

function Workbook() {
    if (!(this instanceof Workbook)) return new Workbook();
    this.SheetNames = [];
    this.Sheets = {};
}

function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export function export_table_to_excel(id) {
    const theTable = document.getElementById(id);
    const oo = generateArray(theTable);
    const ranges = oo[1];

    /* original data */
    const data = oo[0];
    const ws_name = "SheetJS";
    // console.log(data);

    const wb = new Workbook(),
        ws = sheet_from_array_of_arrays(data);

    /* add ranges to worksheet */
    // ws['!cols'] = ['apple', 'banan'];
    ws['!merges'] = ranges;

    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;

    const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        bookSST: false,
        type: 'binary'
    });

    saveAs(new Blob([s2ab(wbout)], {
        type: "application/octet-stream"
    }), "test.xlsx")
}

export function export_json_to_excel({
    title, // 标题
    multiHeader = [],
    header, // 表头 格式:[[],[]]多级菜单，从下往上
    data, // 格式后的数据
    filename, // 表名
    merges = [], // 需要合并的单元格 格式:[[],[]]
    autoWidth = true,
    bookType = 'xlsx'
} = {}) {
    /* original data */
    //判断是否有表名、没有则赋予固定表名
    filename = filename || 'excel-list'
    //获取所有数据
    data = [...data]
    //添加表头进数据最上层
    header.forEach(element => {
        data.unshift(element);
    });
    //添加标题进数据最上层  在表头之上
    data.unshift(title);
    //判断multiHeader是否有值(原作为标题后修改的值)
    for (let i = multiHeader.length - 1; i > -1; i--) {
        data.unshift(multiHeader[i])
    }

    const ws_name = "SheetJS";
    const wb = new Workbook(),
        //将data转化格式 用于接下来动态宽度
        ws = sheet_from_array_of_arrays(data);

    //合并单元格
    if (merges.length > 0) {
        if (!ws['!merges']) ws['!merges'] = [];
        merges.forEach(a => {
            a.forEach(item => {
                ws['!merges'].push(XLSX.utils.decode_range(item))
            })
        })
    }
    //动态设置宽度
    if (autoWidth === true) {
        /*设置worksheet每列的最大宽度*/
        const colWidth = data.map(row => row.map(val => {
            /*先判断是否为null/undefined*/
            if (val == null) {
                return {
                    'wch': 10
                };
            }
            /*再判断是否为中文*/
            else if (val.toString().charCodeAt(0) > 255) {
                return {
                    'wch': val.toString().length * 2
                };
            } else {
                return {
                    'wch': val.toString().length
                };
            }
        }))
        //判断合并单元格中是否有同行合并，有则将其长度设为0
        if (merges.length > 0) {
            if (!ws['!merges']) ws['!merges'] = [];
            merges.forEach(a => {
                a.forEach(item => {
                    let q = item.split(':')
                    let w1 = q[0].substring(0, 1)
                    let e1 = q[0].substring(1, 2)
                    let w2 = q[1].substring(0, 1)
                    let e2 = q[1].substring(1, 2)
                    if (e1 == e2) {
                        let abc = {
                            "A": 0, "B": 1, "C": 2, "D": 3, "E": 4, "F": 5, "G": 6, "H": 7, "I": 8, "J": 9,
                            "K": 10, "L": 11, "M": 12, "N": 13, "O": 14, "P": 15, "Q": 16, "R": 17, "S": 18, "T": 19,
                            "U": 20, "V": 21, "W": 22, "X": 23, "Y": 24, "Z": 25
                        };
                        if (abc[w1] > abc[w2]) {
                            let jh = w1
                            w1 = w2
                            w2 = jh
                        }
                        for (let i = abc[w1]; i <= abc[w2]; i++) {
                            colWidth[e1 - 1][i]['wch'] = 0;
                        }
                    }
                })
            })
        }
        /*以第一行为初始值 判断对应每一列的最大长度*/
        let result = colWidth[0];
        for (let i = 1; i < colWidth.length; i++) {
            for (let j = 0; j < colWidth[i].length; j++) {
                if (result[j]['wch'] < colWidth[i][j]['wch']) {
                    result[j]['wch'] = colWidth[i][j]['wch'];
                }
            }
        }
        ws['!cols'] = result;
    } else {
        // console.log(typeof autoWidth)
        const type = typeof autoWidth
        const result = data[0].map(() => {
            return { wch:  type  === 'number' ? autoWidth : 15 }
        })
        ws['!cols'] = result;
    }

    /* add worksheet to workbook */
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = ws;
    const dataInfo = wb.Sheets[wb.SheetNames[0]];
    console.log(11, dataInfo)

    const borderAll = {  //单元格外侧框线
        top: {
            style: 'thin'
        },
        bottom: {
            style: 'thin'
        },
        left: {
            style: 'thin'
        },
        right: {
            style: 'thin'
        }
    };
    //给所有单元格加上边框
    for (let key in dataInfo) {
        if (key == '!ref' || key == '!merges' || key == '!cols' || key == 'A1') {

        } else {
            // let flag = dataInfo[key].v.indexOf('0') != -1
            let yichang = String(dataInfo[key].v).indexOf('异常') != -1
            
            let zaotui = String(dataInfo[key].v).indexOf('早退') != -1
            let queka = String(dataInfo[key].v).indexOf('缺卡') != -1

            let shijia = String(dataInfo[key].v).indexOf('事假') != -1
            let bingjia = String(dataInfo[key].v).indexOf('病假') != -1
            let hunjia = String(dataInfo[key].v).indexOf('婚假') != -1
            let chanjia = String(dataInfo[key].v).indexOf('产假') != -1
            let sangjia = String(dataInfo[key].v).indexOf('丧假') != -1
            let gongjia = String(dataInfo[key].v).indexOf('公假') != -1
            let waiqin = String(dataInfo[key].v).indexOf('外勤') != -1
            let tiaoxiu = String(dataInfo[key].v).indexOf('调休') != -1
            let weiqiandao = String(dataInfo[key].v).indexOf('未签到') != -1
            let chidao = String(dataInfo[key].v).indexOf('迟到') != -1

            if (yichang || weiqiandao || zaotui || queka) {
                dataInfo[key].s = {
                    // fill: {
                    //     fgColor: { rgb: "46cf74" },
                    // },
                    font: {
                        color: { rgb: "ff0000" },
                    },
                    border: borderAll,
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true     // 是否自动换行
                    },
                }
            }
            else if (shijia || bingjia || hunjia || chanjia || sangjia || gongjia || waiqin || tiaoxiu || chidao) {
                dataInfo[key].s = {
                    // fill: {
                    //     fgColor: { rgb: "46cf74" },
                    // },
                    font: {
                        color: { rgb: "F8A60E" },
                    },
                    border: borderAll,
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true     // 是否自动换行
                    },
                }
            }
            else {
                dataInfo[key].s = {
                    border: borderAll,
                    //居中属性
                    alignment: {
                        horizontal: "center",
                        vertical: "center",
                        wrapText: true    // 是否自动换行
                    },
                }
            }


        }
    }

    // 去掉标题边框
    let arr = ["A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1", "I1", "J1", "K1", "L1", "M1", "N1", "O1", "P1", "Q1", "R1", "S1", "T1", "U1", "V1", "W1", "X1", "Y1", "Z1", "AA1", "AB1", "AC1", "AD1", "AE1", "AF1", "AG1"];
    arr.some(function (v) {
        // let a = merges[0].split(':')
        let a = merges[0][0].split(':')
        if (v == a[1]) {
            dataInfo[v].s = {}
            return true;
        } else {
            dataInfo[v].s = {}
        }
    })

    //设置主标题样式
    dataInfo["A1"].s = {
        font: {
            name: '宋体',
            sz: 20,
            color: { rgb: "ff0000" },
            bold: true,
            italic: false,
            underline: false
        },
        // fill: {
        //     fgColor: { rgb: "46cf74" },
        // },
        alignment: {
            horizontal: "center",
            vertical: "center"
        },
    };


    // console.log(merges)
    // console.log(dataInfo)


    const wbout = XLSX.write(wb, { bookType: bookType, bookSST: false, type: 'binary' });
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `${filename}.${bookType}`);
}